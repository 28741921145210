import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: () => import("../pages/UploadPage.vue"),
    },
    {
      path: "/:downloadLink",
      component: () => import("../pages/DownloadPage.vue"),
    },
    {
        path: "/:catchAll(.*)",
        redirect: "/error"
    },
];

export default new VueRouter({
    mode: "history",
    routes: routes,
});
